<template>
  <div class="instagram-main">
    <div class="card-image wow fadeInLeft">
      <dynamic-template v-if="model.mediaType === 'IMAGE'" name="BackgroundImage" class="social-image"></dynamic-template>

      <dynamic-template v-if="model.mediaType === 'VIDEO'" name="BackgroundVideo" class="social-video"></dynamic-template>
    </div>
    <div class="mainDetails">
      <div class="profile wow fadeInDown" style="text-align:center" data-wow-delay="0.6s">
        <div class="c-image">
          <img :src="model.profileImageUrl" />
        </div>
        <div class="c-title">
          <div class="title">{{ model.userName }}</div>
        </div>
      </div>
      <div class="c-text wow fadeInUp" style="text-align:center;  max-width:100% !important;" data-wow-delay="1.2s">
        <p>{{ getDescription() }}</p>
      </div>
      <div class="info wow fadeInUp" data-wow-delay="2s">
        <div class="count">
          <img src="/images/instagram/like_button.png" />
          <span>{{ model.likeCount }} Beğeni</span>
        </div>

        <div class="count">
          <img src="/images/instagram/comment_button.png" />
          <span>{{ model.commentsCount }} Yorum</span>
        </div>
        <img src="/images/instagram/instagram-icon.png" class="insta-icon" />
      </div>
    </div>
  </div>
</template>

<script>
import DynamicTemplate from "../components/DynamicTemplate";
import WOW from "wow.js";
new WOW().init();
export default {
  name: "instagram",
  props: ["data"],
  components: { DynamicTemplate },
  computed: {
    model() {
      return this.$store.state.instagram;
    }
  },
  methods: {
    getDescription() {
      if (this.model && this.model.description && this.model.description.length) {
        var ind = this.model.description.toUpperCase().indexOf('BİR KERE TATMANIZ YETER');
        if (ind !== -1) {
          return this.model.description.substr(0, ind) + ' BİR KERE TATMANIZ YETER';
        }
        ind = this.model.description.toUpperCase().indexOf('BİR KERE TATMANIZ YETER');
        if (ind !== -1) {
          return this.model.description.substr(0, ind) + ' BİR KERE TATMANIZ YETER';
        }
        return this.model.description;
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/animate/animate.css";

.instagram-main {
  background: #eee;
  overflow: hidden !important;
  font-family: "Montserrat-SemiBold", Arial !important;
}

.card-image {
  // width: 1080px;
  // height: 1080px;
  width: 67%;
  height: 100%;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: left;
  background-color: black;
}

.social-image {
  width: 1080px;
  height: 1080px;
}

.social-video {
  position: fixed;
  width: 1080px;
  height: 1080px;
  right: 0;
  left: 0;
  top: 0;
  text-align: left;
}

/* Sağ bar */

.mainDetails {
  // width: 840px;
  width: 33%;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  padding: 30px;
  background: #fff;
}

.mainDetails .profile {
  width: auto;
  display: block;
  position: relative;
  z-index: 57;
  border-bottom: solid 1px #ddd;
  padding-bottom: 20px;
  height: 25%;
  overflow: hidden;
}

.mainDetails .profile .c-image {
  width: 76px;
  height: 76px;
  display: inline-block;
  vertical-align: top;
  margin-right: 12px;
}

.mainDetails .profile .c-image img {
  width: 100%;
  border-radius: 76px;
}

.mainDetails .profile .c-title {
  width: auto;
  display: inline-block;
  vertical-align: top;
  color: rgb(87, 85, 85);
}

.mainDetails .profile .c-title .title {
  display: block;
  font-size: 32px;
  line-height: 76px;
  font-family: Montserrat-SemiBold;
  color: rgb(87, 85, 85);
  margin: 0;
}

/* Text */

.mainDetails .c-text {
  padding: 44px 0;
  display: block;
  max-width: calc(100% - 220px);
  overflow: hidden;
  max-height: calc(100% - 264px);
  height: calc(75% - 177px);
}

.mainDetails .c-text p {
  display: block;
  font-size: 38px;
  line-height: 54px;
  max-height: 840px;
  display: -webkit-box;
  -webkit-line-clamp: 20;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: Montserrat-SemiBold;
  color: rgb(87, 85, 85);
}

/* Info */

// .mainDetails .info {
//   position: absolute;
//   left: 30px;
//   right: 30px;
//   bottom: 10px;
//   width: calc(100% - 60px);
//   border-top: solid 1px #ddd;
//   padding-top: 20px;
//   height: 120px;
// }


.mainDetails .info .count {
  width: 40%;
  text-align: center;
  display: inline-block;
  margin: 5px 15px;
}

// .mainDetails .info .count img {
//   width: 52px;
// }
.mainDetails .info .count img {
  max-width: 52px;
  width: 33%;
}

.mainDetails .info .count span {
  font-size: 24px;
  color: rgb(87, 85, 85);
  display: block;
  font-family: Montserrat-SemiBold;
}

// .mainDetails .info .insta-icon {
//   // position: absolute;
//    width: 72px;
//    height: auto;
//   // right: 214px;
//   // bottom: 13px;
//   float: right;
//   margin: auto 5%;
// }
.mainDetails .info .insta-icon {
  max-width: 72px;
  height: auto;
  float: right;
  margin: 0 5%;
  width: 15%;
}


.mainDetails .info {
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: 10px;
  width: calc(100% - 60px);
  border-top: solid 1px #ddd;
  padding-top: 20px;
  height: 135px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  bottom: 55px;
}
</style>
